/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://bgvo4i4cdfgfxiv6wunhqi65ua.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5zxelw67nvaebfmg5okjbsg4eu",
    "aws_cognito_identity_pool_id": "us-east-2:0e143563-21d4-4b98-883e-05846d5cf59a",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_eWYrHkJv9",
    "aws_user_pools_web_client_id": "7mvr0levjhqd5poamo47sng94e",
    "oauth": {
        "domain": "auth28122435c-8122435c-staging.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://main.dhisd6r045o28.amplifyapp.com/,https://www.readbookstogether.org/,https://readbookstogether.dhisd6r045o28.amplifyapp.com/,https://192.168.1.4:3000/,https://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/,https://main.dhisd6r045o28.amplifyapp.com/,https://www.readbookstogether.org/,https://readbookstogether.dhisd6r045o28.amplifyapp.com/,https://localhost:3000/,https://192.168.1.4:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "auth271fa9d77521a48a78246e8e2cb04ee7f224759-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
